import React, { useContext, useState } from 'react';
import './Logout.scss';
import { useLocation, useNavigate } from "react-router-dom";
import DataMapping from '../../context/data-mapping';
import LogoutServiceObject from "../../services/LogoutService"
import commonFunctionObject from "../../common-function/commonfunctions";
import QualitricsPopUp from "../../global/QualitricsPopUp"
import { STOREDATA } from "../../context/data-mapping/action/action.type";
import ThemeContext from '../../context/ThemeContext/store/ThemeContext';
import AnalyticsContextProvider from '../../context/AnalyticsContext/AnalyticsContextProvider';

const Logout = () => {
    const [contextData, dispatchData] = useContext(DataMapping)
    const navigate = useNavigate()
    const [qualitricsFlag, setQualitricsFlag] = useState(false);
    const [themeState] = useContext(ThemeContext);
    const { analytics } = useContext(AnalyticsContextProvider);

    const logoutUser = () => {
        try {
            window.closeTCChatbox();
        } catch(error) {
            console.log(error);
        }
        
        var accountNumber = contextData?.currentActiveData.accountNumber;
        var currentUserData = contextData?.parsedUserData[accountNumber];
        var gcid = contextData?.getUserDataV3?.body?.PRIMARY_GCID;
        let productName = contextData?.currentActiveData?.system === "las" ? "LAS" : contextData?.currentActiveData?.system === "ccod" ? "CCOD" : commonFunctionObject.getProductName(currentUserData);
        let analytics_data = {
            user: {
                loginMethod: "",
                loginStatus: "",
                gcid: gcid,
                loginType: "",
                loanaccountNo: accountNumber,
                firstloginDate: ""
            },
            products: {
                productCode: productName,
            },
            data: {
                ctaText: "",
                componentName: "Footer",
                bannerTitle: "",
                menuTitle: "Logout",
                faqTitle: "",
                searchTerm: "",
                lobActivity: "",
                status: "",
                eventName: "Menu Interaction"
            }
        }
        analytics.trackData("menuInteraction", analytics_data);
        if (contextData.getUserDataV3.body.IS_FEEDBACK_SUBMITTED === "" || contextData.getUserDataV3.body.IS_FEEDBACK_SUBMITTED === "N") {
            if (contextData?.["getUserDataV3"]?.body?.otherSystemDetails) {
                var userData = contextData?.["getUserDataV3"].body;
                var lasData = userData?.otherSystemDetails.filter((value) => {
                    return value?.CLIENT_ID;
                });
                var ccodContracts = userData?.otherSystemDetails.filter((element) => {
                    return element.SYSTEM_NAME.toLowerCase() === "ccod";
                });

                var wealthContracts = userData?.otherSystemDetails.filter((element) => {
                    return element.SYSTEM_NAME.toLowerCase() === "wealth";
                });
            }

            if (!(wealthContracts.length > 0 && ccodContracts[0].ccodContractDetails.length == 0 && userData.contracts.length == 0 && lasData.length == 0) && !(lasData.length > 0 && ccodContracts[0].ccodContractDetails.length == 0 && userData.contracts.length == 0 && wealthContracts.length == 0)) {
                setQualitricsFlag(true);
                // qualitricsApiCall();
            } else {
                logOutApiCall();
                navigate("/login", { state: { refresh: true } })
            }

        } else {
            logOutApiCall();
            navigate("/login", { state: { refresh: true } })

        }

    }

    const logOutApiCall = () => {
        var reqObj = {
            "header": {
                "authToken": contextData.authToken
            },
            "body": {}
        }
        dispatchData({
            name: "loaderFlag",
            payload: true,
            type: STOREDATA
        })

        LogoutServiceObject.logOutUserService(reqObj).then(() => {
            dispatchData({
                name: "loaderFlag",
                payload: false,
                type: STOREDATA
            })
            // navigate("/login")
        }).catch(() => {
            dispatchData({
                name: "loaderFlag",
                payload: false,
                type: STOREDATA
            })
            navigate("/login", { state: { refresh: true } })

        })
        navigate("/login", { state: { refresh: true } })
    }
    return (
        <>
            {qualitricsFlag ? <QualitricsPopUp logOutApiCall={logOutApiCall} /> : null}
            <div className="logout-container " onClick={() => { logoutUser() }} >
                <div className="tooltip">
                    <img src="assets/images/logout-icon.svg" alt="logout icon" />
                    <div className={`${themeState === "THEME_DARK" ? "logout-tooltip THEME_DARK" : "logout-tooltip"}`}>
                        <div className="tooltip-text" >Logout</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Logout;