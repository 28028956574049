import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

export const generateSurveyId = (data) => {

    let requestJson = {
        "header": {
            "authToken": "c78fc7068c6f187f19bcfea8092556e370483b3baff25c0235cc8cb634958cae"
        },
        "body": {
            "entity": "TCL",
            "lan": "Test String",
            "accountStatus": "Active",
            "customerName": "Moinuddin Hirapuri",
            "emailId": "moin.hirapuri@gmail.com",
            "mobileNo": "9999999998",
            "userId":"12345",
            "source": "web",
            "sourceSection":"logout",
            "primaryGcid": "7469542",
        "productName":"Two Wheeler Loan"
        }
    }

    return new Promise((resolve, reject) => {
        stalwartApiCall('POST', data, '/shaft/api/qualitrics/generate-survey-id/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    });
}