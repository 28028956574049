import verifyOtp from "../../apis/verify-otp/verify-otp";
import commonFunctionObject from "../../common-function";
import { SplitChargesData } from "../../container/SplitChargesData/SplitChargesData";
import AccountDetailsServicObject from "../AccountDetailsService/AccountDetailsService";
import OnlineDisbursalServiceObject from "../OnlineDisbursal/OnlineDisbursalService";
import OverviewServiceObject from "../Overview";

const SearchServiceObject = () => { }

function getFinalDataService(filterData, parsedUserData, basicDetails, currentActiveAccount, searchArray, data) {
    var finalData = filterData.map((value) => {
        let currentObject = searchArray[value];

        let currentActiveDataCondition = checkCurrentAccount(currentObject, currentActiveAccount, parsedUserData, basicDetails, data);
        if (currentActiveDataCondition) {

            searchArray[value]["contract"] = currentActiveDataCondition;
            return searchArray[value];
        } else {
            let conditionsCheck = checkConditions(currentObject, parsedUserData, basicDetails, data);
            if (conditionsCheck) {
                console.log("FilterData2", filterData);
                searchArray[value]["contract"] = conditionsCheck;
                return searchArray[value];
            }
        }

    }).filter(value => value !== undefined);
    return finalData;
}

SearchServiceObject.getFinalDataService = getFinalDataService;

function checkCurrentAccount(currentObject, currentActiveAccount, allContracts, basicDetails, data) {

    var myparsedUserData = allContracts;
    let systemCheck = checkSystem(myparsedUserData[currentActiveAccount], currentObject, basicDetails, data);
    return systemCheck ? myparsedUserData[currentActiveAccount] : false
}
// function checkConditions(contracts, conditions) {
function checkConditions(currentObject, allContracts, basicDetails, data) {
    var myparsedUserData = allContracts;
    let filteredData = Object.keys(myparsedUserData).filter(value => {
        return checkSystem(myparsedUserData[value], currentObject, basicDetails, data);
    });
    return filteredData.length > 0 ? myparsedUserData[filteredData[0]] : false;
}
SearchServiceObject.checkConditions = checkConditions;

function checkSystem(currentAccountObj, currentObject, basicDetails, data) {
    // currentObject.conditions.system

    let systemCheck = currentObject?.conditions?.system.includes(currentAccountObj?.system?.toLowerCase()) || currentObject?.conditions?.system?.length === 0;

    if (systemCheck && !currentAccountObj?.system?.includes('las')) {
        return checkProductFamily(currentAccountObj, currentObject, basicDetails, data);

    } else if (systemCheck && currentAccountObj?.system?.includes('las')) {
        return true;
    } else {
        return false;
    }

}
SearchServiceObject.checkSystem = checkSystem;

function checkProductFamily(currentAccountObj, currentObject, basicDetails, data) {

    let currentContract = currentAccountObj;
    if (currentContract.cdiContract) {
        var company = currentContract.cdiContract.COMPANY;
        var companyName = "";
        (company.toUpperCase() === "TCFSL" || company.toUpperCase() === "TCL") ? companyName = "CFAB" : companyName = company.toUpperCase();
        var productFamily = companyName;
    } else {
        var productFamily = currentAccountObj.productInfo.PRODUCT_FAMILY;
    }
    if (productFamily) {
        let productFamilyCheck = currentObject.conditions.productFamily.includes(productFamily.toLowerCase()) || currentObject.conditions.productFamily.length === 0;
        if (productFamilyCheck) {
            return checkProduct(currentAccountObj, currentObject, basicDetails, data)
        }
    }
    return false;
}
SearchServiceObject.checkProductFamily = checkProductFamily;


function checkProduct(currentAccountObj, currentObject, basicDetails, data) {
    // currentObject.conditions.product

    let currentContract = currentAccountObj;
    if (currentContract.cdiContract) {
        return true;
    }
    else {
        let productcheck = currentObject.conditions.product.includes(currentAccountObj.productInfo.DISPLAY_NAME.toLowerCase()) || currentObject.conditions.product.length === 0
        if (productcheck) {
            return checkSourceSystem(currentAccountObj, currentObject, basicDetails, data)
        }
    }
    return false;
}
SearchServiceObject.checkProduct = checkProduct;


function checkSourceSystem(currentAccountObj, currentObject, basicDetails, data) {
    let sourceSystemCheck = currentObject.conditions.sourceSystem.includes(currentAccountObj.productInfo.SOURCE_SYSTEM.toLowerCase()) || currentObject.conditions.sourceSystem.length === 0
    if (sourceSystemCheck) {
        let filterLoanDtls = Object.keys(basicDetails).filter(value => value === commonFunctionObject.appendZeros(currentAccountObj.CONTRACT_NUMBER)
        );
        return checkAccStatus(filterLoanDtls, currentObject, basicDetails, data)
    }
    return false;

}
SearchServiceObject.checkSourceSystem = checkSourceSystem;

function checkdatecondition(contracts, basicDetails) {
    let contractAccountdisbursalDate = basicDetails[contracts].DISBURSAL_DATE;
    let checkAgreementAnnexure;
    checkAgreementAnnexure = commonFunctionObject.checkAgreementAnnexure(contractAccountdisbursalDate);
    if (checkAgreementAnnexure) {
        return checkAgreementAnnexure;
    } else {
        return false
    }
}
SearchServiceObject.checkdatecondition = checkdatecondition;

function checkAccStatus(contracts, currentObject, basicDetails, data) {
    let contractAccountStatus = basicDetails[contracts].retailAccountDetails.body.getLoanPositionDtlsReturn.loanStatus;
    let accStatusCheck = currentObject.conditions.accountStatus.includes(contractAccountStatus.toLowerCase()) || currentObject.conditions.accountStatus.length === 0
    var loanStatus = data?.currentActiveData.system.toLowerCase() !== "las" ? commonFunctionObject.checkContractStatus(contractAccountStatus) : "";
    if (accStatusCheck) {
        if (currentObject.label.toLowerCase() == "loan agreement") {
            return checkdatecondition(contracts, basicDetails);
        } else if (currentObject.label.toLowerCase() == "final it certificate" && contractAccountStatus.toLowerCase() == "cancelled") {
            return false;
        } else if (currentObject.label.toLowerCase() == "provisional it certificate") {
            if (contractAccountStatus.toLowerCase() != "cancelled") {
                if (!loanStatus) {
                    let loanStatusDetails = basicDetails[contracts].retailAccountDetails?.body.getLoanPositionDtlsReturn;
                    let checkEmptyData = commonFunctionObject.checkEmptyData(loanStatusDetails?.terminationDate, "-")
                    if (checkEmptyData != "-") {
                        let [startOfFinancialYear, endOfFinancialYear] = OverviewServiceObject.getCurrentFinancialYear(data?.dateAndTime);
                        let inCurrentYear = OverviewServiceObject.isTerminatedInCurrentFinancialYear(loanStatusDetails?.terminationDate, startOfFinancialYear, endOfFinancialYear);
                        if (inCurrentYear) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }
            } else {
                return false;
            }
        } else {
            return accStatusCheck;
        }
    }
    return false;
}
SearchServiceObject.checkAccStatus = checkAccStatus;


const redirectionCall = (stmtObj, themeState, data) => {

    return new Promise((resolve, reject) => {

        var stmtLabel = stmtObj.label;


        switch (stmtLabel) {
            case "Statement of Account":
                resolve(redirectToSOADocumentPage(stmtObj));
                break;
            case "Repayment Schedule":
            case "Loan Agreement":
            case "Welcome Letter":
            case "Loan Sanction Letter":
            case "Loan Documents":
            case "No Dues Certificate":
            case "Provisional IT certificate":
            case "Final IT certificate":
                resolve(redirectToDocumentPage(stmtObj));
                break;
            case "Foreclosure Letter":
                resolve(ForeclosureRedirection(stmtObj));
                break;
            // case "Subsequent Disbursement":
            //     resolve(subseqDisbursementRedirection(stmtObj));
            //     break;
            case "Update Mobile Number":
                resolve(mobNoSRRedirection(stmtObj));
                break;
            case "Update Email ID":
                resolve(emailSRRedirection(stmtObj));
                break;
            case "Update Address":
                resolve(addressRedirection(stmtObj));
                break;
            case "Update TAN":
                resolve(tanRedirection(stmtObj));
                break;
            case "Bill Payment":
                resolve(bbpsRedirection(stmtObj, themeState, data).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error)
                }))
                break;
            case "Pay your Overdue":
                resolve(payoverdueApicall(stmtObj, themeState, data).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                }))
                break;
            case "Self Service":
                resolve(customerServiceRedirection(stmtObj));
                break;
            case "Pre-Approved Offer":
                resolve(offersRedirection(stmtObj));
                break;
            case "Account Details":
                resolve(loanDetailsRedirection(stmtObj));
                break;
            case "Bank Details":
                resolve(bankDetailsRedirection(stmtObj));
                break;
            case "Fees and Charges":
                resolve(spiltChargesRedirection(stmtObj));
                break;
            case "Credit Score":
                resolve(creditScoreRedirection(stmtObj));
                break;
            case "Profile Details":
                resolve(profileDirection(stmtObj));
                break;
            case "Invest now":
                resolve(investmentRedirection(stmtObj));
                break;
            case "Loan Details":
                resolve(loanDetailsRedirection(stmtObj));
                break;
            case "Stop Mandate":
                resolve(mandateRedirection(stmtObj));
                break;
            case "Update Date of Birth":
                resolve(dobRedirection(stmtObj));
                break;
            case "Change Bank Account":
                resolve(nachSwapRedirection(stmtObj));
                break;
            case "Online Disbursal":
                resolve(onlineDisbursalRedirectioon(stmtObj));
                break;
            case "Pay your Overdue Amount":
                resolve(payOnlineRedirection(stmtObj, themeState, data).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error)
                }));
                break;
            case "Overdue Summary":
                resolve(overDueRedirection(stmtObj));
                break;
            case "Disbursement":
            case "Pay Now":
            case "Incremental Pledging":
            case "Depledge":
            case "Holdings":
                resolve(redirectToLasTransactPage(stmtObj));
                break;
            case "Account Statement":
            case "Holding Statement":
                resolve(redirectAndHighlightLAS(stmtObj));
                break;
            // case "Loan Details":
            // case "Interest/interest due/due":
            // case "Charges/other charges/charge/fee/fee":
            case "Drawing power":
                // case "Sanction/Sanction limit/Limit/Loan limit":
                resolve(redirectToLasLoanDetailsPage(stmtObj));
                break;
            case "Others":
                resolve(otherSrRedirection(stmtObj));
                break;
            case "No Data Found":
                resolve("No Data Found");
                break;
            default:
                reject("NO Data Found");
        }
    })
}
SearchServiceObject.redirectionCall = redirectionCall;


const redirectToSOADocumentPage = (stmtObj) => {

    if (stmtObj.contract.system.toLowerCase() === "las") {
        return {
            navigateTo: "/overview",
            contractObj: stmtObj.contract,


        }
    } else if (stmtObj.contract.system.toLowerCase() === "retail") {
        return {
            navigateTo: "/account-details",
            state: { redirectionFrom: "soa" },
            contractObj: stmtObj.contract,
        }
        // navigate("/account-details", { state: { redirectionFrom: "soa" } });
    } else if (stmtObj.contract.system.toLowerCase() === "ccod") {
        return {
            navigateTo: "/account-details",
            state: { redirectionFrom: "Search Documents" },
            contractObj: stmtObj.contract
        }
    }
}
SearchServiceObject.redirectToSOADocumentPage = redirectToSOADocumentPage;


const redirectToDocumentPage = (stmtObj) => {

    if (stmtObj.contract.system.toLowerCase() === "las") {
        return {
            navigateTo: "/overview",
            contractObj: stmtObj.contract,
            state: { scrollDown: true }

        }
    } else if (stmtObj.contract.system.toLowerCase() === "retail") {
        return {
            navigateTo: "/account-details",
            state: { redirectionFrom: "soa", scrollDown: true },
            contractObj: stmtObj.contract,
        }
        // navigate("/account-details", { state: { redirectionFrom: "soa" } });
    } else if (stmtObj.contract.system.toLowerCase() === "ccod") {
        return {
            navigateTo: "/account-details",
            state: { redirectionFrom: "Search Documents", scrollDown: true },
            contractObj: stmtObj.contract
        }
    }
}
SearchServiceObject.redirectToDocumentPage = redirectToDocumentPage;

// const finalItPopUp = (stmtObj) => {

// }
// SearchServiceObject.finalItPopUp = finalItPopUp;

const ForeclosureRedirection = (stmtObj) => {

    let product_Name = commonFunctionObject.getProductName(stmtObj.contract)

    if (AccountDetailsServicObject.checkForeclosure(stmtObj.contract, product_Name)) {
        return {
            navigateTo: "/account-details",
            state: { redirectionFrom: "Documents", scrollDown: true },
            contractObj: stmtObj.contract
        }
    } else {
        return {
            navigateTo: "/customer-service",
            state: { screenName: "foreclosure-statement" },
            contractObj: stmtObj.contract
        }
    }
}
SearchServiceObject.ForeclosureRedirection = ForeclosureRedirection;

const subseqDisbursementRedirection = (stmtObj) => {
    return {
        navigateTo: "/customer-service",
        state: { screenName: "subsequent-disbursement" },
        contractObj: stmtObj.contract
    }

}
SearchServiceObject.subseqDisbursementRedirection = subseqDisbursementRedirection;

const mobNoSRRedirection = (stmtObj) => {
    return {
        navigateTo: "/customer-service",
        state: { screenName: "change-mobile-number" },
        contractObj: stmtObj.contract
    }
}

SearchServiceObject.mobNoSRRedirection = mobNoSRRedirection;

const emailSRRedirection = (stmtObj) => {
    return {
        navigateTo: "/customer-service",
        state: { screenName: "change-email-id" },
        contractObj: stmtObj.contract
    }
}

SearchServiceObject.emailSRRedirection = emailSRRedirection;

const addressRedirection = (stmtObj) => {
    return {
        navigateTo: "/customer-service",
        state: { screenName: "change-address" },
        contractObj: stmtObj.contract
    }
}

SearchServiceObject.addressRedirection = addressRedirection;

const tanRedirection = (stmtObj) => {
    return {
        navigateTo: "/customer-service",
        state: { screenName: "change-tan-number" },
        contractObj: stmtObj.contract
    }
}

SearchServiceObject.tanRedirection = tanRedirection;

const bbpsRedirection = (themeState, data) => {
    return new Promise((resolve, reject) => {
        let requestObj = {
            header: {
                authToken: data.authToken,
                theme: themeState === "THEME_LIGHT" ? "light" : "dark"
            },
            body: {
                mobileNumber: data.loginMobileNo,
            },
        };
        OverviewServiceObject.billPayService(requestObj).then((finalUrl) => {
            resolve(finalUrl)

        }).catch((error) => {
            reject(error);
        });
    })

}
SearchServiceObject.bbpsRedirection = bbpsRedirection;

const payoverdueApicall = (stmtObj, themeState, data) => {


    var accountNumber = stmtObj.contract.CONTRACT_NUMBER;
    var currentUserData = data?.parsedUserData[accountNumber]
    let email = commonFunctionObject.getLastestFieldValFromField(stmtObj.contract.EMAIL)
    let mobile = commonFunctionObject.getLastestFieldValFromField(stmtObj.contract.MOBILENO)
    let productName = stmtObj.contract.system === "las" ? "LAS" : stmtObj.contract.system === "ccod" ? "CCOD" : commonFunctionObject.getProductName(stmtObj.contract);
    return new Promise((resolve, reject) => {
        const reqObj = {
            "header": {
                "authToken": data?.authToken,
                "transactionType": "od",
                "hash": true,
                "encrypt": true
            },
            "body": {
                "gcid": currentUserData.GCID,
                "primaryGcid": data?.getUserDataV3?.body?.PRIMARY_GCID,
                "contractNo": accountNumber,
                "company": currentUserData.COMPANY,
                "emailId": email,
                "mobileNo": mobile,
                "productName": productName
            }
        }

        OverviewServiceObject.payOverdueService(reqObj, data?.authToken).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error)
        })
    })

}

SearchServiceObject.payoverdueApicall = payoverdueApicall;

const payOnlineRedirection = (stmtObj, themeState, data) => {
    var accountNumber = stmtObj.contract.sapContract.CUSTOMER_NO;
    var gcid = stmtObj.contract.cdiContract.GCID;
    var userid = stmtObj.contract.cdiContract.USER_ID;
    var customerName = stmtObj.contract.sapContract.CUSTOMER_FIRST_NM;
    var company = stmtObj.contract.cdiContract.COMPANY;
    var companyName = "";
    (company.toUpperCase() === "TCFSL" || company.toUpperCase() === "TCL") ? companyName = "CFAB" : companyName = company.toUpperCase();
    var reqObj = {
        "header": {
            "authToken": data.authToken,
            "company": companyName

        },
        "body": {
            "contractNo": accountNumber,
            "userId": userid,
            "primaryGcid": gcid,
            "customerName": customerName
        }
    }
    return new Promise((resolve, reject) => {
        OnlineDisbursalServiceObject.callPayOnlineAPI(reqObj).then((uniqueTransactionId) => {
            OnlineDisbursalServiceObject.paymentTransactionStatus(uniqueTransactionId, data.authToken).then(() => {
                resolve()
            }).catch((error) => {
                reject(error);
            })
        }).catch((error) => {
            reject(error);
        })
    })
}
SearchServiceObject.payOnlineRedirection = payOnlineRedirection;

const customerServiceRedirection = (stmtObj) => {
    return {
        navigateTo: "/customer-service",
        contractObj: stmtObj.contract
    }
}

SearchServiceObject.customerServiceRedirection = customerServiceRedirection;

const loanDetailsRedirection = (stmtObj) => {

    if (stmtObj.contract.system.toLowerCase() === 'retail') {
        return {
            navigateTo: "/account-details",
            state: { redirectionFrom: "Search Loan Details" },
            contractObj: stmtObj.contract
        }
    } else if (stmtObj.contract.system.toLowerCase() === 'ccod') {
        return {
            navigateTo: "/account-details",
            state: { redirectionFrom: "Search Account Details" },
            contractObj: stmtObj.contract
        }
    } else if (stmtObj.contract.system.toLowerCase() === 'las') {

        return {
            navigateTo: "/account-details",
            state: { navigationObject: { tabName: "Loan Details" } },
            contractObj: stmtObj.contract
        }
    }

}
SearchServiceObject.loanDetailsRedirection = loanDetailsRedirection;

const bankDetailsRedirection = (stmtObj) => {
    if (stmtObj.contract.cdiContract) {
        var accountNumber = stmtObj.contract.sapContract.CUSTOMER_NO;
    }
    else {
        var accountNumber = stmtObj.contract.CONTRACT_NUMBER
    }
    return {
        navigateTo: "/profile",
        state: { redirectionFlag: 'bank', accountnumber: accountNumber },
        contractObj: stmtObj.contract
    };
}
SearchServiceObject.bankDetailsRedirection = bankDetailsRedirection;

const spiltChargesRedirection = (stmtObj) => {
    return {
        navigateTo: "/account-details",
        state: { redirectionFrom: "split-charges" },
        contractObj: stmtObj.contract
    }
}
SearchServiceObject.spiltChargesRedirection = spiltChargesRedirection;

const calculatorRedirection = (callback) => {
    callback(true)
}
SearchServiceObject.calculatorRedirection = calculatorRedirection;

const whatsAppRedirection = (callback) => {
    callback(true)
}
SearchServiceObject.whatsAppRedirection = whatsAppRedirection;

const mandateRedirection = (stmtObj) => {
    return {
        navigateTo: "/customer-service",
        state: { screenName: "stop-cancel-mandate" },
        contractObj: stmtObj.contract
    }
}

SearchServiceObject.mandateRedirection = mandateRedirection

const dobRedirection = (stmtObj) => {
    return {
        navigateTo: "/customer-service",
        state: { screenName: "change-dob" },
        contractObj: stmtObj.contract
    }
}
SearchServiceObject.dobRedirection = dobRedirection;

const profileDirection = (stmtObj) => {
    if (stmtObj.contract.cdiContract) {
        var accountNumber = stmtObj.contract.sapContract.CUSTOMER_NO;
    }
    else {
        var accountNumber = stmtObj.contract.CONTRACT_NUMBER
    }
    return {
        navigateTo: "/profile",
        state: { redirectionFlag: 'profile', accountnumber: accountNumber },
        contractObj: stmtObj.contract
    };
}

// change-bank-account-for-emi-deduction

const nachSwapRedirection = (stmtObj) => {
    return {
        navigateTo: "/customer-service",
        state: { screenName: "change-bank-account-for-emi-deduction", currentActiveData: stmtObj.contract },
        contractObj: stmtObj.contract
    }
}
SearchServiceObject.nachSwapRedirection = nachSwapRedirection;

const onlineDisbursalRedirectioon = (stmtObj) => {
    return {
        navigateTo: "/online-disbursal",
        contractObj: stmtObj.contract
    }
}
SearchServiceObject.onlineDisbursalRedirectioon = onlineDisbursalRedirectioon;

const overDueRedirection = (stmtObj) => {
    return {
        navigateTo: "/overDue-summary",
        contractObj: stmtObj.contract
    }
}
SearchServiceObject.overDueRedirection = overDueRedirection;

const offersRedirection = (stmtObj) => {

    return {
        navigateTo: "/overview",
        contractObj: stmtObj.contract,
        state: { scrollDown: true }
    }
}


const creditScoreRedirection = (stmtObj) => {
    return {
        navigateTo: '/credit-score',
        contractObj: stmtObj.contract
    };
}
SearchServiceObject.creditScoreRedirection = creditScoreRedirection;

const investmentRedirection = (stmtObj) => {
    return {
        navigateTo: '/investment',
        contractObj: stmtObj.contract
    };
}
SearchServiceObject.investmentRedirection = investmentRedirection;

const redirectToLasLoanDetailsPage = (stmtObj) => {
    return {
        navigateTo: "/account-details",
        state: { navigationObject: { tabName: "Loan Details" } },
        contractObj: stmtObj.contract
    }
}
SearchServiceObject.redirectToDocumentPage = redirectToLasLoanDetailsPage;

const redirectToLasTransactPage = (stmtObj) => {

    return {
        navigateTo: "/account-details",
        state: { navigationObject: { tabName: "Transact" } },
        contractObj: stmtObj.contract
    }
}
SearchServiceObject.redirectToLasTransactPage = redirectToLasTransactPage;

const redirectAndHighlightLAS = (stmtObj) => {
    return {
        navigateTo: '/overview',
        contractObj: stmtObj.contract,
        state: { scrollDown: true }
    }
}
SearchServiceObject.redirectAndHighlightLAS = redirectAndHighlightLAS;

const otherSrRedirection = (stmtObj) => {
    return {
        navigateTo: "/customer-service",
        state: { screenName: "others" },
        contractObj: stmtObj.contract
    }
}


export default SearchServiceObject;